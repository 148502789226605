<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    alt: String,
    image: Object,
    imageNL: Object,
    maxWidth: String,
    minWidth: String,
    classes: String,
});

const locale = computed(() => usePage().props.locale);
const height = computed(() =>
    locale.value === 'nl' ? props.imageNL?.height ?? props.image.height : props.image.height,
);
const width = computed(() => (locale.value === 'nl' ? props.imageNL?.width ?? props.image.width : props.image.width));
</script>

<template>
    <picture>
        <source
            v-if="minWidth"
            :width="width"
            :height="height"
            srcset="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            :media="`(max-width: ${minWidth})`"
        >
        <source
            v-if="maxWidth"
            :width="width"
            :height="height"
            srcset="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            :media="`(min-width: ${maxWidth})`"
        >

        <img
            v-if="locale == 'nl'"
            :alt="alt"
            :class="classes"
            :height="height"
            :src="imageNL.src"
            :width="width"
        >
        <img
            v-else
            :alt="alt"
            :class="classes"
            :height="height"
            :src="image.src"
            :width="width"
        >
    </picture>
</template>
